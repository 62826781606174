<template>
  <RevForm
    :initial-values
    method="POST"
    :validate="validate()"
    @on-submit-failed="handleSubmitFailed"
    @submit="handleSubmit"
  >
    <template #default="{ values, errors }">
      <RevCard class="p-24">
        <div class="heading-3 mb-12 md:mb-24">
          {{ i18n(translations.billingAddressTitle) }}
        </div>
        <DynamicAddressFieldValidatorsErrorLogger
          :errors
          :form-values="values"
          logger-scope="CHECKOUT_BILLING"
        />
        <AddressFields
          v-model:city="values.city"
          v-model:company="values.company"
          v-model:country="values.country"
          v-model:first-name="values.firstName"
          v-model:first-name-pronunciation="values.firstNamePronunciation"
          v-model:last-name="values.lastName"
          v-model:last-name-pronunciation="values.lastNamePronunciation"
          v-model:postal-code="values.postalCode"
          v-model:state-or-province="values.stateOrProvince"
          v-model:street="values.street"
          v-model:street2="values.street2"
          :countries
          :default-country="MARKET_COUNTRY_CODE"
          :errors="allErrors(errors)"
          :feature-code="FeatureCode.WEB_CHECKOUT_BILLING"
          :optional-fields="['company']"
          @autocompleted="handleAddressAutocomplete"
        />

        <div v-if="shouldDisplayUserInformation(values.country)">
          <RevDivider class="my-16 md:my-24" />
          <h2 class="heading-3 mb-16">
            {{ i18n(translations.userInformationTitle) }}
          </h2>
          <UserInformationFields
            v-model:birthdate="values.birthdate"
            v-model:national-id="values.nationalId"
            :country="values.country"
            :errors
            :with-birthdate="withUserInformation"
            :with-national-id="userInformationWithNationalId(values.country)"
          />

          <div class="mt-20 flex flex-col items-stretch md:items-end">
            <RevButton
              data-qa="submit-button"
              :disabled="submitting"
              full-width="adaptive"
              type="submit"
              variant="primary"
            >
              {{
                withCheckoutContentCTA
                  ? i18n(translations.submitABTest)
                  : i18n(translations.submit)
              }}
            </RevButton>
          </div>
        </div>

        <div
          v-if="
            !withUserInformation &&
            !shouldDisplayUserInformation(values.country)
          "
          class="mt-32 flex flex-col items-stretch md:items-end"
        >
          <RevButton
            data-qa="submit-button"
            :disabled="submitting"
            full-width="adaptive"
            type="submit"
            variant="primary"
          >
            {{
              withCheckoutContentCTA
                ? i18n(translations.submitABTest)
                : i18n(translations.submit)
            }}
          </RevButton>
        </div>
      </RevCard>
    </template>
  </RevForm>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'

import { Country } from '@backmarket/http-api'
import type { Bill } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import type { AddressFieldsValues } from '@backmarket/http-api/src/api-specs-shipping/address/address-validation.types'
import AddressFields from '@backmarket/nuxt-module-address/AddressFields.vue'
import { AddressFieldsValidators } from '@backmarket/nuxt-module-address/AddressFieldsValidators'
import DynamicAddressFieldValidatorsErrorLogger from '@backmarket/nuxt-module-address/DynamicAddressFieldValidatorsErrorLogger.vue'
import { InputAddressBirthdateValidators } from '@backmarket/nuxt-module-address/InputAddressBirthdateValidators'
import UserInformationFields from '@backmarket/nuxt-module-address/UserInformationFields.vue'
import { FeatureCode } from '@backmarket/nuxt-module-address/featureCode'
import { useDynamicAddressFieldValidators } from '@backmarket/nuxt-module-address/useDynamicAddressFieldValidators'
import { isNationalIdShownForBilling } from '@backmarket/nuxt-module-address/utils/nationalId/isNationalIdShownForBilling'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { omit } from '@backmarket/utils/object/omit'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'
import {
  type ErrorsOf,
  RevForm,
  type ValidatorDeclaration,
  makeValidate,
} from '@ds/components/Form'

import { CHECKOUT_LOG_TYPES } from '~/scopes/checkout/config/constants'
import { useUserInformationStore } from '~/scopes/checkout/stores/userInformationStore'

import translations from './BillingAddressForm.translations'
import type { FormValues } from './BillingAddressForm.types'

const props = withDefaults(
  defineProps<{
    submitting?: boolean
    address: Bill
    apiErrors: { [key: string]: string }
    countries?: {
      value: string
      label: string
    }[]
    withUserInformation?: boolean
  }>(),
  {
    submitting: false,
    withUserInformation: false,
    countries: () => [],
  },
)
const i18n = useI18n()
const tracking = useTracking()

// @TODO: Need to do this cast because MarketCountryCode and Country are not compatible
const MARKET_COUNTRY_CODE = useMarketplace().market
  .countryCode as unknown as Country

const userInformationStore = useUserInformationStore()
const { getDynamicAddressFieldValidators } = useDynamicAddressFieldValidators()

const experiments = useExperiments()

const initialValues = reactive<FormValues>({
  city: props.address.city ?? '',
  company: props.address.company ?? '',
  country: (props.address.country as Country) ?? '',
  firstName: props.address.firstName ?? '',
  lastName: props.address.lastName ?? '',
  nationalId: props.address.customerIdNumber ?? '',
  postalCode: props.address.postalCode ?? '',
  stateOrProvince: props.address.stateOrProvince ?? '',
  street: props.address.street ?? '',
  street2: props.address.street2 ?? '',
  ...insertIf(userInformationStore.isFormRequired, {
    birthdate: props.address.birthdate ?? '',
  }),
  ...insertIf(props.address.country === Country.JP, {
    firstNamePronunciation: props.address.firstNamePronunciation ?? '',
    lastNamePronunciation: props.address.lastNamePronunciation ?? '',
  }),
})

const emit = defineEmits<{
  submit: [values: Omit<FormValues, 'nationalId'>]
  'submit-failed': [errors: ErrorsOf<Omit<FormValues, 'nationalId'>>]
}>()

const withCheckoutContentCTA = computed(
  () =>
    experiments['experiment.checkoutContentCTA'] === 'checkoutContentDetailed',
)

// Need to fix nationalId validation
const fallbackValidators: ValidatorDeclaration<AddressFieldsValues> = {
  ...AddressFieldsValidators(i18n),
  ...insertIf(userInformationStore.isFormRequired, {
    birthdate: InputAddressBirthdateValidators(i18n),
    // nationalId: InputAddressNationalIdValidators(i18n),
  }),
}

const addressValidation = await getDynamicAddressFieldValidators({
  featureFlag: 'FF_ENABLE_ADDRESS_FIELD_VALIDATION_CHECKOUT_BILLING',
  initialFormValues: initialValues,
  fallbackValidators,
  metadata: {
    scope: 'customer',
  },
})

function validate() {
  return makeValidate<FormValues>(addressValidation)
}

const allErrors = (errors: Partial<Record<keyof FormValues, string>>) => {
  return { ...errors, ...props.apiErrors }
}

const userInformationWithNationalId = (
  selectedCountry: FormValues['country'],
) => {
  return !!selectedCountry && isNationalIdShownForBilling(selectedCountry)
}

const handleSubmitFailed = ({ errors }: { errors: ErrorsOf<FormValues> }) => {
  emit('submit-failed', {
    ...omit(errors, 'nationalId'),
    ...errors,
  })
}

const handleSubmit = (values: FormValues) => {
  emit('submit', {
    ...omit(values, 'nationalId'),
    ...insertIf(
      !!values.country &&
        isNationalIdShownForBilling(values.country) &&
        !isEmpty(values.nationalId),
      {
        customerIdNumber: values.nationalId,
      },
    ),
  })
}

const handleAddressAutocomplete = (
  address: { [key: string]: string },
  index: number,
) => {
  tracking.trackChange({
    name: '[CHECKOUT] Address autocomplete',
    optionIndex: index,
    address,
    isShipping: false,
    isBilling: true,
    isCollectionPoint: false,
    type: CHECKOUT_LOG_TYPES.ADDRESS_AUTOCOMPLETE,
  })
}

const shouldDisplayUserInformation = (country: FormValues['country']) => {
  return props.withUserInformation || userInformationWithNationalId(country)
}
</script>
