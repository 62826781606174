export default {
  submit: {
    id: 'checkout_billing_address_form_submit',
    defaultMessage: 'Next',
  },
  submitABTest: {
    id: 'checkout_billing_address_form_submit_abtest',
    defaultMessage: 'Go to confirmation',
  },
  userInformationTitle: {
    id: 'checkout_billing_user_information_title',
    defaultMessage: 'Personal information',
  },
  billingAddressTitle: {
    id: 'checkout_billing_page_information_title',
    defaultMessage: 'Billing Address',
  },
}
